import * as React from "react";
import styled from "styled-components";

export type ImagesPreloaderProps = {
  images: string[];
};

export const ImagesPreloader: React.FC<ImagesPreloaderProps> = ({
  images,
}: ImagesPreloaderProps) => (
  <>
    {images.map((imageUrl, index) => (
      <Image
        aria-hidden={true}
        key={index}
        src={imageUrl}
        width="1"
        height="1"
        alt={`Image ${index}`}
      />
    ))}
  </>
);

const Image = styled.img`
  display: none;
`;

export default ImagesPreloader;
